/* .ant-btn-default{
    background-color: rgb(212, 29, 29) !important;
} */
.ant-btn-default:hover{
    border: 1px solid #D65746 !important;
    color: #D65746 !important;;
}
.ant-btn-primary{
    background-color:   #D65746
}
.ant-btn-primary:hover{
    background-color:   #D65746 !important
}
.ant-modal-close{
    display: none !important;
}