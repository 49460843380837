.invalid-feedback {
  border-radius: 10px;
  padding: 0.12rem 0.25rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: $foreground-color;
  background: #d65746;
  color: white;
  font-weight: 400;
  border: 1px solid $primary-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: 2.6rem;
  // right: 10%;

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    // left: -5px;
    margin-left: 5px;
    border-bottom: solid 10px $primary-color;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}
.invalid-insight-feedback {
  border-radius: 10px;
  padding: 0rem 0.25rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: $foreground-color;
  background: #d65746;
  color: white;
  font-weight: 400;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: 2.23rem;
  // right: 10%;

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    // left: -5px;
    margin-left: 5px;
    border-bottom: solid 10px $primary-color;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.invalid-feedback-button-left {
  display: inline;
  border-radius: 5px;
  padding: 0.12rem 0.25rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: $foreground-color;
  background: #d65746;
  color: white;
  font-weight: 400;
  border: 1px solid $primary-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -1.25rem;
  // right: 10%;
  // transform: translateX(-50%);
  //   @include depth(1);

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: -15px;
    margin-left: 20px;

    border-top: solid 10px $primary-color;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.invalid-feedback-button-right {
  display: inline;
  border-radius: 10px;
  padding: 0.12rem 0.25rem;
  font-size: 0.76rem;
  background: #d65746;
  color: white;
  font-weight: 400;
  border: 1px solid $primary-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -1.25rem;
  transform: translateX(-90%);
  //   @include depth(1);

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    // left: -15px;
    right: 10px;
    // margin-left: 20px;
    transform: translateX(-50%);

    border-top: solid 10px $primary-color;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.invalid-feedback-text {
  display: inline;
  border-radius: 5px;
  padding: 0.12rem 0.25rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: #d65746;
  color: white;
  font-weight: 400;
  border: 1px solid $primary-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -1.75rem;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: -15px;
    margin-left: 20px;

    border-top: solid 10px $primary-color;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.invalid-feedback-text-right {
  display: inline;
  border-radius: 5px;
  padding: 0.12rem 0.25rem;
  font-size: 0.76rem;
  color: $primary-color;
  background: #d65746;
  color: white;
  font-weight: 400;
  border: 1px solid $primary-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -1.75rem;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: -15px;
    margin-left: 80%;

    border-top: solid 10px $primary-color;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.invalid-title {
  display: inline;
  border-radius: 5px;
  padding: 0.12rem 0.25rem;
  font-size: 0.76rem;
  background: #d65746;
  color: white;
  font-weight: 400;
  border: 1px solid $primary-color;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -1.75rem;
}
