.ReactTable
  .rt-thead
  .rt-th.-cursor-pointer.-sort-desc
  > div:first-of-type:after {
  color: $primary-color;
}

.ReactTable
  .rt-thead
  .rt-th.-cursor-pointer.-sort-asc
  > div:first-of-type:after {
  color: $primary-color;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  color: gray;
}
